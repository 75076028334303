@use 'Theme';

.inputItem {
    display: flex;
    flex-direction: column;
    margin: 0 0 10px 0;
    width: 100%;
}

.inputItem h1 {
    font: 400 20px Theme.$font-stack;
}

.inputItem h2 {
    font: 400 20px Theme.$font-stack;
    margin-left: 10px;
}

.inputItem span {
    align-items: center;
    display: flex;
}

.ratingModal {
    width: 100%;
}

.termSheetItem {
    border: none;
    border-bottom: 1px solid Theme.$medium-gray;
    font: 400 16px Theme.$font-stack;
    margin: 5px 0 10px 0;
    outline: none;
    padding: 0 0 5px 0;
}