@use 'Theme';

$padding: 40px;
$modal-padding: 80px * 2;
$summary-height: 161px;

@keyframes pop {
    0% {
        // opacity: 0;
        -webkit-transform: scale(1.0);
        transform: scale(1.0)
    }
    25% {
        // opacity: 0;
        -webkit-transform: scale(1.1);
        transform: scale(1.1)
    }
    50% {
        // opacity: 1;
        -webkit-transform: scale(1.0);
        transform: scale(1.0)
    }
    70% {
        -webkit-transform: scale(1.0);
        transform: scale(1.0)
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}

@keyframes pop-large {
    0% {
        opacity: 0;
        -webkit-transform: scale(0.7);
        transform: scale(0.7)
    }
    25% {
        opacity: 0;
        -webkit-transform: scale(0.7);
        transform: scale(0.7)
    }
    50% {
        opacity: 1;
        -webkit-transform: scale(1.0);
        transform: scale(1.0)
    }
    70% {
        -webkit-transform: scale(0.9);
        transform: scale(0.9)
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}

.prospect {
    animation: pop-large 0.5s ease-in;
    background: Theme.$white;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    height: calc(100vh - #{$modal-padding});
    // max-width: 716px;
    width: 716px;
    max-width: 900px;
    overflow: hidden;
    transition: max-width 0.3s ease-in, width 0.3s ease-in;
    width: calc(100% - 30px);
}

.container {
    // align-items: center;
    display: flex;
    flex: 1;
    flex-direction: row;
    overflow: hidden;
    padding: 30px 0 0 0;
}

.main {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.prospectCheckbox {
    align-items: center;
    cursor: pointer;
    display: flex;
    flex: 1;
    font-family: Theme.$font-stack;
    font-size: 18px;
    font-weight: 400;
    height: 24px;
    margin: 0 20px 0 0;
    padding: 0 0 0 24px + 10px;
    position: relative;
}

.prospectCheckbox input {
    cursor: pointer;
    opacity: 0;
    position: absolute;
}

.prospectCheckbox input:checked ~ .prospectCheckmark {
    background: Theme.$blue;
    border: none;
}

.prospectCheckbox input:checked ~ .prospectCheckmark:after {
    border: solid Theme.$white;
    border-width: 0 3px 3px 0;
    display: block;
    height: 12px;
    left: 8px;
    top: 2px;
    transform: rotate(45deg);
    width: 5.6px;
}

.prospectCheckboxAutoprediction {
    color: Theme.$blue;
    font-family: Theme.$font-stack;
    font-size: 17px;
    font-weight: 500;
}

.prospectCheckboxContainer {
    align-items: center;
    border: 2px solid transparent;
    border-radius: 5px;
    display: flex;
    height: 24px;
    margin: 10px 0 0 10px;
    padding: 0 $padding/4;
    // left: 0;
    // position: absolute;
    // top: -5px;
    width: calc(100% - 20px);
}

.prospectCheckmark {
    border: 2px solid Theme.$black;
    height: 24px;
    left: 0;
    position: absolute;
    top: 0;
    width: 24px;
}

.prospectCheckmark:after {
    content: "";
    display: none;
    position: absolute;
}



.prospectContent {
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    // padding: 30px 0 0 0;
}

.prospectDivider {
    background: rgba(0, 0, 0, 0.25);
    height: 1px;
    margin: 30px 0 20px 0;
    width: calc(100% - #{$padding * 2});
}

.prospectFooter {
    align-items: center;
    background: Theme.$base-color;
    display: flex;
    height: 56px;
    justify-content: center;
    width: 100%;
}

.prospectFooter h1 {
    color: #AFAFAF;
    font-family: Theme.$font-stack;
    font-size: 18px;
    font-weight: 500;
    margin: 0;
}

.prospectFooterRed {
    align-items: center;
    background: Theme.$red;
    display: flex;
    height: 56px;
    justify-content: center;
    width: 100%;
}

.prospectFooterRed h1 {
    color: Theme.$white;
    font-family: Theme.$font-stack;
    font-size: 18px;
    font-weight: 500;
    margin: 0;
}

.prospectInfoContainer {
    $top-indicator-height: 12px;
    $divider-height: 1px;
    $divider-padding: 30px + 20px;
    $footer-height: 56px;

    align-items: center;
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - #{$summary-height + $top-indicator-height + $divider-height + $padding + $modal-padding + $divider-padding + $footer-height});
    overflow: scroll;
    width: 100%;
}

.prospectInfoItem {
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    margin: 3px 0 3px 0;
    // padding: 6px $padding;
    position: static;
    width: calc(100% - #{$padding});
}

.prospectInfoItem h1 {
    color: Theme.$black;
    font-family: Theme.$font-stack;
    font-size: 20px;
    font-weight: 500;
    margin: 0;
}

.prospectInfoItem h2 {
    color: Theme.$black;
    font-family: Theme.$font-stack;
    font-size: 18px;
    font-weight: 400;
    margin: 10px 0 0 0;
}

.prospectInfoItemAutofill {
    cursor: pointer;
    padding: 5px 14px;
}

.prospectInfoItemAutofill:hover {
    background: Theme.$base-color;
}

.prospectInfoItemAutofill span {
    font-family: Theme.$font-stack;
    font-size: 14px;
    font-weight: 400;
}

.prospectInfoItemDisplay {
    padding: 10px $padding/2;
}

.prospectInfoItemDisplay:hover {
    border-radius: 3px;
    box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.25);
    cursor: pointer;
}

.prospectInfoItemDisplayStatic {
    display: flex;
    flex-direction: column;
    padding: 10px 0;
}

.prospectInfoItemDisplayStatic h1 {
    padding: 0 $padding/2;
}

.prospectInfoItemEditor {
    animation: pop 0.5s ease-in;
    background: Theme.$white;
    border-radius: 3px;
    box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.25);
    margin: 5px 0 0 0;
    width: 200px;
    padding: 12px 0 5px 0;
    position: absolute;
    z-index: 5;
}

.prospectInfoItemEditor h2 {
    color: #AAAAAA;
    font-family: Theme.$font-stack;
    font-size: 13px;
    font-weight: 400;
    padding: 0 14px;
    margin: 0 0 7px 0;
}

.prospectInfoItemEditor input {
    border: 1px solid #AAAAAA;
    border-radius: 3px;
    font-family: Theme.$font-stack;
    font-size: 15px;
    font-weight: 400;
    height: 30px;
    margin: 0 7px 5px 7px;
    padding: 0 7px;
    width: calc(100% - 14px);
}

.prospectInfoItemEditorDisplay {
    padding: 10px $padding/2;
}

.prospectInfoItemEditorPicker {
    max-height: 200px;
    overflow-y: scroll;
}

.prospectInfoItemEditorPickerCancel {
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
}

.prospectInfoItemEditorPickerDivider {
    background: rgba(0, 0, 0, 0.25);
    height: 1px;
    width: 100%;
}

.prospectScore {
    height: 82px;
    margin: 0 0 10px 0;
    position: relative;
    width: 82px;
}

.prospectScore span {
    color: Theme.$black;
    font-family: Theme.$font-stack;
    font-size: 20px;
    font-weight: 900;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}

.prospectSummaryArea {
    align-items: center;
    display: flex;
    height: $summary-height;
    justify-content: space-between;
    padding: 0 $padding;
    width: 100%;
}

.prospectSummary {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
}

.prospectSummary h1 {
    color: Theme.$black;
    font-family: Theme.$font-stack;
    font-size: 30px;
    font-weight: 700;
    margin: 0;
}

.prospectSummary a {
    color: Theme.$black;
    font-family: Theme.$font-stack;
    font-size: 20px;
    font-weight: 400;
    margin: 0;
    padding: 5px 0 0 0;
    text-decoration: none;
}

.prospectSummary h2 {
    color: Theme.$black;
    font-family: Theme.$font-stack;
    font-size: 20px;
    font-weight: 400;
    margin: 0;
    padding: 7px 0 14px 0;
}

.prospectSummaryLeft {
    align-items: center;
    display: flex;
    flex-direction: column;
}

.prospectTopIndicator {
    background: #04B900;
    height: 12px;
    width: 100%;
}

.termSheetBasicsTable {
    font: 400 18px Theme.$font-stack;
    margin: 5px 0 0 0;
    padding: 0 18px;
    // width: min-content;
}

.termSheetBasicsTable .label {
    color: Theme.$black;
    padding: 0 25px 7px 0;
    white-space: nowrap;
}

.termSheetBasicsTable .value {
    color: #979797;
    width: 100%;
}

.termSheetBasicsInput {
    border: none;
    font: 400 18px Theme.$font-stack;
    outline: none;
    padding: 0 0 7px 0;
    width: 100%;
}

.termSheetBasicsInput::placeholder {
    color: #979797;
}