@use '../../styles/Theme';

.bar {
    align-items: center;;
    background: Theme.$off-white;
    display: flex;
    height: Theme.$nav-bar-height;
    justify-content: space-between;
    padding: 0 Theme.$padding;
    position: relative;
    z-index: 1;
}

.barText h3 {
    font: 500 18px Theme.$font-stack;
    margin: 0 0 5px 0;
}

.barText p {
    font: 400 15px Theme.$font-stack;
    margin: 0;
}

.infoLayout {
    display: grid;
    flex-wrap: wrap;
    grid-template-columns: 1fr 1fr;
    padding: 43px;
}

.item {
    flex: 1;
    margin: 0 43px 26px 0;
}

.item h1 {
    font: 500 20px Theme.$font-stack;
}

.item textarea {
    background: Theme.$white;
    border: none;
    border-radius: 5px;
    font: 400 17px Theme.$font-stack;
    height: 150px;
    line-height: 25px;
    padding: 13px;
    resize: none;
    width: 100%;
}