@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');

.googleButton {
    align-items: center;
    background: #4285F4;
    border-radius: 2px;
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.24);
    cursor: pointer;
    display: flex;
    height: 50px;
    /* padding: 11px; */
    width: 250px;
}

.googleButton:hover {
    background: #3367D6;
}

.googleButton p {
    color: #FFFFFF;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    letter-spacing: 0.22px;
    line-height: 16px;
    margin: auto;
    padding: 0;
}

.googleLogo {
    height: 18px;
    width: 18px;
}

.googleLogoArea {
    align-items: center;
    background: #FFFFFF;
    border-radius: 1px;
    display: flex;
    justify-content: center;
    margin: 1px 0 1px 1px;
    padding: 15px;
}