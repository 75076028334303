@use 'Theme';

@keyframes openNotes {
    0% {
        width: 0;
    }
    100% {
        width: 300px;
    }
}

.note h2 {
    color: #979797;
    font: 400 15px Theme.$font-stack;
    margin: 0 0 5px 0;
}

.note p {
    color: Theme.$black;
    font: 400 18px Theme.$font-stack;
    margin: 0 0 20px 0;
}

.notes {
    flex: 1;
    overflow: scroll;
    margin: 20px 0 0 0;
}

.notesArea {
    animation: openNotes 0.3s linear;
    border-left: 1px solid rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    margin: 0 0 30px 0;
    overflow: hidden;
    padding: 20px;
    width: 300px;
}

.notesArea h1 {
    color: Theme.$black;
    font: 500 18px Theme.$font-stack;
    margin: 0 0 15px 0;
}

.noteInput {
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font: 400 15px Theme.$font-stack;
    height: 100px;
    line-height: 23px;
    padding: 10px;
    resize: none;
    width: 100%;
}

.notesSave {
    align-items: flex-end;
    display: flex;
    justify-content: flex-end;
    margin: 10px 0 0 0;
    width: 100%;
}