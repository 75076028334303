@use 'Theme';

.centered {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 0 60px 0;
    width: 100%;
}

.competitors {
    background: Theme.$white;
    max-width: 90%;
    width: 500px;
}

.competitorOption {
    align-items: center;
    display: flex;
    position: static;
    width: 100%;
}

.competitorOption .logo {
    border-radius: 50%;
    margin-right: 15px;
    width: 30px;
}

.competitorOption .hover {
    display: none;
}

.competitorOption:hover .hover {
    border-radius: 5px;
    display: block;
    left: calc(-100px - 10px);
    position: absolute;
    width: 100px;
}

.container {
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: center;
    position: relative;
    width: 100%;
}

.footer {
    display: grid;
    column-gap: 20px;
    grid-template-columns: 1fr 1fr;
    position: absolute;
    bottom: 60px;
}

.grid {
    display: grid;
    column-gap: 20px;
    grid-template-columns: 1fr 1fr;
    max-width: 90%;
    width: 500px;
}

.message {
    font: 400 25px Theme.$font-stack;
    margin: 0 0 20px 0;
}

.selector {
    background: Theme.$white;
    // border: 2px solid Theme.$medium-gray;
    border-radius: 5px;
    height: 50px;
    padding: 0 10px;
    width: 300px;
}

.submessage {
    color: Theme.$dark-gray;
    font: 400 20px Theme.$font-stack;
    margin: -10px 0 20px 0;
}

.text {
    background: Theme.$white;
    border: 2px solid Theme.$medium-gray;
    border-radius: 5px;
    font: 400 17px Theme.$font-stack;
    // height: 50px;
    padding: 0 10px;
    // width: 300px;
}