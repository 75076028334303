@use 'Theme';

.buttonLarge {
    border: none;
    border-radius: 5px;
    box-shadow: 1px 3px 3px 0px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    font-family: Theme.$font-stack;
    font-size: 15px;
    font-weight: 500;
    outline: none;
    padding: 17px 44px;
}

.buttonSmall {
    border: none;
    border-radius: 5px;
    box-shadow: 1px 3px 3px 0px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    font-family: Theme.$font-stack;
    font-size: 15px;
    font-weight: 400;
    outline: none;
    padding: 9px 23px;
}

.backgroundBlack {
    background: Theme.$black;
    color: Theme.$white;
}

// .backgroundBlack:hover {
//     background: adjust-color(Theme.$yellow, $blackness: 5%);
// }

.backgroundGray {
    background: Theme.$medium-gray;
    color: Theme.$white;
}

.backgroundGray:hover {
    background: adjust-color(Theme.$medium-gray, $blackness: 5%);
}

.backgroundGreen {
    background: Theme.$green;
    color: Theme.$white;
}

.backgroundGreen:hover {
    background: adjust-color(Theme.$green, $blackness: 5%);
}

.backgroundRed {
    background: Theme.$red;
    color: Theme.$white;
}

.backgroundRed:hover {
    background: adjust-color(Theme.$red, $blackness: 5%);
}

.backgroundYellow {
    background: Theme.$yellow;
    color: Theme.$black;
}

.backgroundYellow:hover {
    background: adjust-color(Theme.$yellow, $blackness: 5%);
}

.disabled {
    background: Theme.$medium-gray;
    box-shadow: none;
    color: Theme.$white;
}

.modalContainer {
    $padding: 0;
    align-items: center;
    display: flex;
    height: 100vh;
    justify-content: center;
    left: 0;
    padding: $padding 30px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
}

.modalBackground {
    background: rgba(0, 0, 0, 0.50);
    height: 100vh;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
}

.loginSection h1 {
    color: #FFFFFF;
    font: 400 50px Theme.$font-stack;
    margin: 0 0 10px 0;
}

.loginSection h2 {
    color: #FFFFFF;
    font: 400 25px Theme.$font-stack;
    margin: 0 0 23px 0;
}