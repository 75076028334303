@use '../../styles/Theme';

.container {
    display: flex;
    flex: 1;
    overflow-y: scroll;
    position: relative;
    width: 100%;
}

.items {
    border-collapse: collapse;
    overflow: scroll;
    padding: 43px;
    text-align: left;
    width: 100%;
}

.items td, th {
    padding: 0 30px 0 60px;
}

.item {
    // background: Theme.$white;
    border-bottom: 5px solid Theme.$base-color;
    position: relative;
    height: 69px;
    z-index: 1;
}

// .item::after {
//     background: Theme.$white;
//     border-radius: 5px;
//     content: "";
//     height: 64px;
//     left: 30px;
//     margin: 15px 0 0 0;
//     position: absolute;
//     width: calc(100% - 60px);
//     z-index: 0;
// }

.item td {
    background: Theme.$white;
    font: 400 18px Theme.$font-stack;
    padding: 0 30px 0 60px;
    position: relative;
    z-index: 1;
}

.layout {
    background: Theme.$base-color;
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: hidden;
    position: relative;
    width: 100%;
}

.pearIndicator {
    background: url("../../images/pear.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 20px;
    margin: 0 10px 0 0;
    width: 20px;
}

.scoreIndicator {
    border-radius: 50%;
    height: 20px;
    margin: 0 10px 0 0;
    overflow: visible;
    position: relative;
    width: 20px;
}

// Modal
.modal {
    align-items: flex-start;
    background: Theme.$white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    // height: calc(100vh - #{$modal-padding});
    max-width: 900px;
    overflow: hidden;
    padding: 40px 70px;
    width: calc(100% - 30px);
}

.modal h1 {
    font: 600 30px Theme.$font-stack;
    margin: 0;
}

.modal h2 {
    font: 500 20px Theme.$font-stack;
    margin: 20px 0 10px 0;
}

.modal .buttons {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.modal .buttons * {
    width: 200px;
}

.modal .controls {
    align-items: baseline;
    display: flex;
    justify-content: space-between;
}

.modal .controls button {
    background: Theme.$blue;
    border: none;
    border-radius: 3px;
    color: Theme.$white;
    cursor: pointer;
    font: 400 15px Theme.$font-stack;
    outline: none;
    padding: 5px 15px;
}

.modal .controls button:hover {
    background: adjust-color(Theme.$blue, $blackness: 20%);
}

.modal .inputs {
    padding: 0 0 30px 0;
    width: 100%;
}

.modal .controls .insertTag {
    // background: Theme.$blue;
    border: none;
    border-radius: 3px;
    // color: Theme.$white;
    cursor: pointer;
    font: 400 15px Theme.$font-stack;
    outline: none;
    width: 150px;
}

.modal .email {
    border: 1px solid Theme.$medium-gray;
    border-radius: 5px;
    cursor: not-allowed;
    font: 400 17px Theme.$font-stack;
    height: 40px;
    padding: 0 10px;
    width: 100%;
}

.modal .editor {
    border: 1px solid Theme.$medium-gray;
    border-radius: 5px;
    font: 400 17px Theme.$font-stack;
    height: 200px;
    line-height: 25px;
    padding: 10px;
    width: 100%;
}

// .modal .editor span {
//     display: inline-block;
// }

.modal .editor tag content {
    display: flex;
}

// .modal .editor span {
//     background: Theme.$blue;
//     border-radius: 3px;
//     color: Theme.$white;
//     font-size: 15px;
//     padding: 2px 5px;
// }

// .modal .editor tag button {
//     background: Theme.$blue;
//     border: none;
//     border-radius: 0 3px 3px 0;
//     color: Theme.$white;
// }

// Table

@keyframes slide-in {
    0% {
        transform: translate(0, -100%);
    }
    100% {
        transform: translate(0, 0);
    }
}

.toolbar {
    display: flex;
    justify-content: space-between;
}

// Create Request Popup
.createGroup {
    align-items: center;
    display: flex;
    position: relative;
}

.createInput {
    margin: 0 10px 0 0 !important;
    width: 150px;
}

.createPopup {
    align-items: center;
    display: flex;
    padding: 0 10px 10px 10px;
}

// Floating Alerts
.floatingAlerts {
    height: auto;
    max-width: 400px;
    padding: 20px;
    position: fixed;
    right: 0;
    top: 0;
    width: 90%;
    z-index: 100;
}