// Fonts
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');
$font-stack: Roboto, sans-serif;

// Colors
$action-color: #000000;
$base-color: #EBEBEB;
$black: #000000;
$blue: #3C5AF4;
$dark-gray: #696969;
$green: #04B900;
$medium-gray: #AFAFAF;
$off-white: #FBFBFB;
$primary-color: #181818;
$red: #FF3434;
$white: #FFFFFF;
$yellow: #FFD234;

// Dimensions
$content-padding: 47px;
$nav-bar-height: 100px;
$padding: 45px;