@use 'Theme';

.base {
    display: flex;
    justify-content: flex-start;
    width: 100%;
}

.home {
    align-items: center;
    background: linear-gradient(to right, #05156A , #8415AB);
    display: flex;
    height: 100vh;
    justify-content: space-between;
    padding: 0 120px;
    width: 100%;
}

.loading {
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: center;
    width: 100%;
}

.navBar {
    align-items: center;;
    background: Theme.$off-white;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    height: Theme.$nav-bar-height;
    justify-content: space-between;
    padding: 0 Theme.$padding;
    position: relative;
    // width: calc(100% - #{Theme.$padding * 2});
    z-index: 2;
}

.navBar h1 {
    color: Theme.$black;
    font-family: Theme.$font-stack;
    font-size: 30px;
    font-weight: 400;
}

.navDrawer {
    background: Theme.$primary-color;
    flex: 1;
    overflow: hidden;
    transition: width 0.3s ease-in;
    width: 250px;
}

.navDrawerContainer {
    background: Theme.$primary-color;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: flex-start;
}

.navDrawerControl:checked + .navDrawerToggle img {
    transform: scaleX(-1);
}

.navDrawerControl:checked + .navDrawerToggle + .navDrawer {
    width: 80px;
}

.navDrawerControl:checked + .navDrawerToggle + .navDrawer .navDrawerItem h1 {
    display: none;
}

.navDrawerControl {
    margin: 20px 20px 20px auto;
    opacity: 0;
    position: absolute;
}

.navDrawerItem {
    cursor: pointer;
    display: flex;
    padding: 15px 0;
}

.navDrawerItem:hover {
    background: adjust-color(Theme.$primary-color, $blackness: 10%);
}

.navDrawerItem h1 {
    color: Theme.$white;
    font-family: Theme.$font-stack;
    font-size: 20px;
    font-weight: 400;
    margin: 0;
}

.navDrawerItem img {
    height: 25px;
    object-fit: contain;
    // padding: 0 40px 0 0;
    // width: 80px;
    width: 30px;
}

.navDrawerItemIcon {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 0 30px;
    width: 80px;
}

.navDrawerToggle {
    align-items: center;
    display: flex;
    height: 60px;
    justify-content: flex-end;
    margin: 0 0 20px 0;
    padding: 0 20px 0 0;
    width: 100%;
}

.navDrawerToggle img {
    cursor: pointer;
    height: 25px;
    object-fit: cover;
    transition: transform 0.3s ease-in;
}

.navDrawerToggle img:hover {
    opacity: 0.8;
}