@use '../../styles/Theme';

.buttons {
    display: flex;
    max-width: 500px;
    width: 100%;
}

.buttons * {
    flex: 1;
    width: 100%;
}

.createPartnerInputs {
    display: grid;
    grid-template-areas:
        "email"
        "fn ln";
}

.domain {
    @extend %inputItem;
    grid-area: domain;
}

.domain::placeholder, .name::placeholder, .stage:invalid, .tier:invalid, .stage::placeholder, .tier::placeholder, .type:invalid {
    color: Theme.$medium-gray;
}

.gridInputs {
    column-gap: 15px;
    display: grid;
    grid-template-areas:
        "type name"
        "domain tier";
    grid-template-columns: 1fr 1fr;
    margin: 0 0 30px 0;
    row-gap: 15px;
    width: 100%;
}

%inputItem {
    border: 2px solid #979797;
    border-radius: 5px;
    font: 500 17px Theme.$font-stack;
    height: 45px;
    padding: 0 17px;
}

.inputs {
    align-items: center;
    display: flex;
    margin: 0 0 30px 0;
    width: 100%;
}

.name {
    @extend %inputItem;
    grid-area: name;
}

.intermediateLabel {
    font: 500 17px Theme.$font-stack;
    margin: 0 20px;
}

.intro {
    align-items: center;
    background: Theme.$white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    // height: calc(100vh - #{$modal-padding});
    max-width: 600px;
    overflow: hidden;
    padding: 40px 70px;
    width: calc(100% - 30px);
}

.partners {
    flex: 1;
    // width: 100%;
}

.stage {
    @extend %inputItem;
    grid-area: stage;
}

.investor {
    color: Theme.$green;
}

.company {
    color: Theme.$blue;
}

.partnerName {
    color: Theme.$black;
    font: 300 17px Theme.$font-stack;
    margin: 0 10px;
    text-align: right;
    width: 100%;
}

.role {
    color: Theme.$medium-gray;
    font: 300 17px Theme.$font-stack;
    margin: 0 10px;
    text-align: right;
    width: 100%;
}

.strengthIndicator {
    border-radius: 50%;
    height: 16px;
    margin: 0 10px 0 0;
    padding: 0 8px;
}

.green {
    background: Theme.$green;
}

.red {
    background: Theme.$red;
}

.yellow {
    background: Theme.$yellow;
}

.tier {
    @extend %inputItem;
    grid-area: tier;
}

.title {
    font: 500 25px Theme.$font-stack;
    line-height: 35px;
    margin: 0 0 30px 0;
    text-align: center;
}

.type {
    @extend %inputItem;
    grid-area: type;
}