@use '../../styles/Theme';

.items {
    overflow: scroll;
    padding: 43px;
}

.left {
    align-items: center;
    display: flex;
}

.logo {
    margin: 0 20px 0 0;
    width: 50px;
}

.portfolioLayout {
    background: Theme.$base-color;
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: hidden;
    position: relative;
    width: 100%;
}

.portfolioItem {
    background: Theme.$white;
    border-radius: 5px;
    box-shadow: 1px 3px 3px 0px rgba(0, 0, 0, 0.25);
    cursor: pointer !important;
    margin: 0 0 20px 0;
    overflow: hidden;
    width: 100%;
}

.portfolioItem h1 {
    color: Theme.$black;
    font-family: Theme.$font-stack;
    font-size: 18px;
    font-weight: 700;
    height: auto;
    margin: 0 0 7px 0;
}

.portfolioItem h2 {
    color: Theme.$dark-gray;
    font-family: Theme.$font-stack;
    font-size: 17px;
    font-weight: 400;
    margin: 0 0 0 0;
}

.portfolioItemConflict {
    color: Theme.$red;
    font: 400 14px Theme.$font-stack;
    font-variant: small-caps;
    margin: 12px 0 -8px 0;
}

.portfolioItemContent {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 10px 18px;
}

.portfolioItemHeader {
    // background: Theme.$green;
    height: 7px;
    width: 100%;
}

.portfolioItemFooter {
    align-items: center;
    background: #EEEEEE;
    display: flex;
    height: 35px;
    justify-content: space-between;
    // margin: 18px 0 0 0;
    padding: 0 18px;
    width: 100%;
}

.portfolioItemFooter span {
    color: Theme.$black;
    font-family: Theme.$font-stack;
    font-size: 15px;
    font-variant: small-caps;
    font-variant-caps: all-small-caps;
    font-weight: 400;
    margin: 0;
}

.portfolioItemScore {
    height: 56px;
    position: relative;
    width: 56px;
}

.portfolioItemScore span {
    color: Theme.$black;
    font-family: Theme.$font-stack;
    font-size: 11px;
    font-weight: 900;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}

.prospectInfo {
    margin: 0 20px 0 0;
}

.prospectInfo h3 {
    font: 600 18px Theme.$font-stack;
    font-variant-caps: all-small-caps;
    margin: 0;
    text-align: right;
}

.right {
    align-items: center;
    display: flex;
}