@use 'Theme';

.addButton {
    align-items: center;
    background: Theme.$primary-color;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    height: 24px;
    justify-content: center;
    width: 24px;
}

.addButton img {
    width: 12.8px;
}

.kanbanArea {
    flex: 1;
    display: flex;
    overflow-x: scroll;
    padding: Theme.$content-padding Theme.$padding 0 Theme.$padding;
    width: 100%;
}

.kanbanColumn {
    // background: red;
    display: flex;
    flex-direction: column;
    margin: 0 28px 0 0;
    height: calc(100% - 30px);
    width: 228px;
}

.kanbanColumn .kanbanColumnTitle {
    cursor: pointer;
    flex: 1;
    // height: 30px;
    margin: 0;
}

.kanbanColumn h1, .kanbanColumnCollapsed h1 {
    color: Theme.$black;
    font-family: Theme.$font-stack;
    font-size: 15px;
    font-weight: 400;
}

.kanbanColumnCollapsed {
    // background: purple;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    margin: 0 38px 0 10px;
    overflow: hidden;
    padding: 0 0 30px 0;
    position: relative;
    min-width: 35px;
}

.kanbanColumnCollapsed h1 {
    // margin: 0;
    writing-mode: vertical-rl;
}

.kanbanColumnCollapsedTitle {
    align-items: center;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 15px;
}

.kanbanColumnCollapsedTitle .addButton {
    margin: 10px 0 0 0;
}

.kanbanColumnContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    position: relative;
}

.kanbanColumnCounter {
    align-items: center;
    background: Theme.$primary-color;
    border-radius: 50%;
    display: flex;
    height: 26px;
    justify-content: center;
    margin: 10px 0 0 0;
    width: 26px;
}

.kanbanColumnCounter p {
    color: Theme.$white;
    font-family: Theme.$font-stack;
    font-size: 12px;
    font-weight: 500;
    margin: 0;
}

.kanbanColumnTitleArea {
    align-items: center;
    display: flex;
    height: 24px;
    justify-content: space-between;
    margin: 0 0 8px 0;
    width: 100%;
}

.kanbanColumnDropArea {
    display: flex;
    flex: 1;
    max-height: calc(100vh - #{Theme.$nav-bar-height + Theme.$nav-bar-height + Theme.$content-padding + 30px});
    overflow-y: scroll;
}

.kanbanItem {
    background: Theme.$white;
    border-radius: 5px;
    box-shadow: 1px 3px 3px 0px rgba(0, 0, 0, 0.25);
    cursor: pointer !important;
    margin: 0 0 10px 0;
    overflow: hidden;
    width: 228px;
}

.kanbanItem h1 {
    color: Theme.$black;
    font-family: Theme.$font-stack;
    font-size: 13px;
    font-weight: 700;
    height: auto;
    margin: 12px 0 7px 0;
}

.kanbanItem h2 {
    color: Theme.$dark-gray;
    font-family: Theme.$font-stack;
    font-size: 13px;
    font-weight: 400;
    margin: 0 0 0 0;
}

.kanbanItemConflict {
    color: Theme.$red;
    font: 400 14px Theme.$font-stack;
    font-variant: small-caps;
    margin: 12px 0 -8px 0;
}

.kanbanItemContent {
    margin: 0 18px 0 18px;
}

.kanbanItemHeader {
    // background: Theme.$green;
    height: 7px;
    width: 100%;
}

.kanbanItemFooter {
    align-items: center;
    background: #EEEEEE;
    display: flex;
    height: 35px;
    justify-content: space-between;
    margin: 18px 0 0 0;
    padding: 0 18px;
    width: 100%;
}

.kanbanItemFooter span {
    color: Theme.$black;
    font-family: Theme.$font-stack;
    font-size: 13px;
    font-weight: 400;
    margin: 0;
}

.kanbanItemScore {
    height: 28px;
    position: relative;
    width: 28px;
}

.kanbanItemScore span {
    color: Theme.$black;
    font-family: Theme.$font-stack;
    font-size: 9px;
    font-weight: 900;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}