@use 'Theme';

$arrow-width: 28px;
$search-height: 65px;

@keyframes pop-large {
    0% {
        opacity: 0;
        -webkit-transform: scale(0.7);
        transform: scale(0.7)
    }
    25% {
        opacity: 0;
        -webkit-transform: scale(0.7);
        transform: scale(0.7)
    }
    50% {
        opacity: 1;
        -webkit-transform: scale(1.0);
        transform: scale(1.0)
    }
    70% {
        -webkit-transform: scale(0.9);
        transform: scale(0.9)
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}

.arrow {
    display: none;
    position: absolute;
    right: 25px;
    width: $arrow-width;
    z-index: 3;
}

.container {
    align-items: center;
    animation: pop-large 0.5s ease-in;
    display: flex;
    justify-content: center;
    margin: 0 0 50px 0;
    max-width: 90%;
    position: relative;
    width: 510px;
}

.search {
    border: none;
    border-radius: 10px;
    color: Theme.$black;
    font: 400 18px Theme.$font-stack;
    height: $search-height;
    outline: none;
    padding: 0 $arrow-width + 25px + 12px 0 25px;
    width: 100%;
    z-index: 2;
}

.search::placeholder {
    color: #BFBFBF;
    font: 400 18px Theme.$font-stack;
}

.search:valid {
    border-radius: 10px 10px 0 0;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}

.search:valid + .arrow {
    display: block;
}

.search:valid + .arrow + .suggestionsContainer {
    display: block;
}

.suggestion {
    color: #979797;
    cursor: pointer;
    font: 400 15px Theme.$font-stack;
    padding: 15px 25px;
}

.suggestion:hover {
    background: Theme.$base-color;
}

.suggestionsContainer {
    background: Theme.$white;
    border-radius: 0 0 10px 10px;
    display: none;
    left: 0;
    max-height: 200px;
    overflow-y: scroll;
    position: absolute;
    top: $search-height;
    width: 100%;
    z-index: 0;
}