@use 'Theme';

.buttons {
    column-gap: 15px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    max-width: 500px;
}

.buttons * {
    flex: 1;
}

.domain {
    @extend %inputItem;
    grid-area: domain;
}

.domain::placeholder, .name::placeholder, .stage:invalid, .tier:invalid, .stage::placeholder, .tier::placeholder, .type:invalid {
    color: Theme.$medium-gray;
}

.gridInputs {
    column-gap: 15px;
    display: grid;
    grid-template-areas:
        "type name"
        "domain tier";
    grid-template-columns: 1fr 1fr;
    margin: 0 0 30px 0;
    row-gap: 15px;
    width: 100%;
}

%inputItem {
    // border: 2px solid #979797;
    // border-radius: 5px;
    font: 500 17px Theme.$font-stack;
    // height: 45px;
    // padding: 0 17px;
}

.inputs {
    column-gap: 15px;
    display: grid;
    grid-template-areas:
        "firm firm"
        "domain domain"
        "stage tier";
    grid-template-columns: 1fr 1fr;
    margin: 0 0 30px 0;
    row-gap: 7.5px;
    width: 100%;
}

.name {
    @extend %inputItem;
    grid-area: name;
}

.firm {
    @extend %inputItem;
    grid-area: firm;
}

.modal {
    align-items: center;
    background: Theme.$white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    // height: calc(100vh - #{$modal-padding});
    max-width: 700px;
    overflow: hidden;
    padding: 40px 70px;
    width: calc(100% - 30px);
}

.partner, .rating {
    align-items: center;
    background: Theme.$white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    // height: calc(100vh - #{$modal-padding});
    max-width: 600px;
    overflow: hidden;
    padding: 40px 70px;
    width: calc(100% - 30px);
}

.stage {
    @extend %inputItem;
    grid-area: stage;
}

.term {
    color: Theme.$green;
}

.tier {
    @extend %inputItem;
    grid-area: tier;
}

.title {
    font: 500 25px Theme.$font-stack;
    line-height: 35px;
    margin: 0 0 30px 0;
    text-align: center;
}

.type {
    @extend %inputItem;
    grid-area: type;
}