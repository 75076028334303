@use 'Theme';

.financingBar {
    align-items: center;;
    background: Theme.$off-white;
    display: flex;
    height: Theme.$nav-bar-height;
    justify-content: space-between;
    padding: 0 Theme.$padding;
    position: relative;
    z-index: 1;
}

.financingBarData {
    align-items: center;
    display: flex;
}

.financingBarItem h1 {
    font-family: Theme.$font-stack;
    font-size: 15px;
    font-weight: 400;
    margin: 0;
}

.financingBarItem h2 {
    font-family: Theme.$font-stack;
    font-size: 20px;
    font-weight: 400;
    margin: 10px 0 0 0;
}

.financingBarItems {
    align-items: center;
    display: flex;
    justify-content: flex-start;
}

.financingBarSeparator {
    background: #D3D3D3;
    height: 60px;
    margin: auto 35px;
    width: 1px;
}

.pipelineLayout {
    background: Theme.$base-color;
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    width: 100%;
}