@use '../../styles/Theme';

.companyLayout {
    background: Theme.$base-color;
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: hidden;
    position: relative;
    width: 100%;
}

.view {
    width: 250px;
}